var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "my-box",
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            { attrs: { shadow: "always" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "topS",
                  attrs: { "label-position": "right", "label-width": "80px" },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0px" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _vm.showadd != -1
                            ? _c("el-button", { on: { click: _vm.addBtn } }, [
                                _vm._v("新增"),
                              ])
                            : _vm._e(),
                          _vm.showdelete != -1
                            ? _c(
                                "el-button",
                                { on: { click: _vm.batchesDelete } },
                                [_vm._v("批量删除")]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#fff",
                                display: "inline-block",
                              },
                            },
                            [_vm._v(".")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色名" } },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.roleName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.seekData, "roleName", $$v)
                                  },
                                  expression: "seekData.roleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建人" } },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.createUser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.seekData, "createUser", $$v)
                                  },
                                  expression: "seekData.createUser",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建时间" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "-",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.createTime,
                                  callback: function ($$v) {
                                    _vm.createTime = $$v
                                  },
                                  expression: "createTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "reset-button", attrs: { span: 7 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", loading: _vm.loading },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c("el-button", { on: { click: _vm.reset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-card", { attrs: { shadow: "always" } }, [
            _c(
              "div",
              { staticClass: "my-header-table" },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      height: _vm.height,
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "roleName", label: "角色" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "createUser", label: "创建人" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "创建时间" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.createTime))]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "修改时间" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.modifyTime))]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "150" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.showupdate != -1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        dialogFormVisible: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.showdelete != -1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next, sizes, total, jumper",
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.seekData.pageSize,
                    "current-page": _vm.seekData.pageNum,
                    total: _vm.total,
                  },
                  on: {
                    "current-change": _vm.handleCurrentChange,
                    "size-change": _vm.handleSizeChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑角色",
            visible: _vm.dialogFormVisible2,
            "before-close": _vm.handleCloseEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editData",
              attrs: { model: _vm.editData, rules: _vm.myrules },
            },
            [
              _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "默认登录页面",
                        prop: "rolePath",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.editData.rolePath,
                            callback: function ($$v) {
                              _vm.$set(_vm.editData, "rolePath", $$v)
                            },
                            expression: "editData.rolePath",
                          },
                        },
                        _vm._l(_vm.routerData, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称",
                    prop: "roleName",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号" },
                    model: {
                      value: _vm.editData.roleName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "roleName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入描述信息" },
                    model: {
                      value: _vm.editData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editData, "remark", $$v)
                      },
                      expression: "editData.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "权限选择",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-tree", {
                    ref: "trees",
                    attrs: {
                      data: _vm.menuTreeData,
                      "default-checked-keys": _vm.editData.menuId,
                      "show-checkbox": "",
                      "highlight-current": "",
                      accordion: "",
                      "check-strictly": "",
                      "node-key": "id",
                      props: _vm.defaultProps,
                      "default-expand-all": true,
                    },
                    on: { check: _vm.clickDeal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("editData")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmEditD("editData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增角色",
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: { model: _vm.addform, rules: _vm.myrules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "默认登录页面",
                    prop: "rolePath",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.rolePath,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "rolePath", $$v)
                        },
                        expression: "addform.rolePath",
                      },
                    },
                    _vm._l(_vm.routerData, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称",
                    prop: "roleName",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.addform.roleName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "roleName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.addform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "remark", $$v)
                      },
                      expression: "addform.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "权限选择",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.menuTreeData,
                      "node-key": "id",
                      "default-checked-keys": _vm.addform.menuId,
                      "highlight-current": "",
                      "show-checkbox": "",
                      "check-strictly": "",
                      accordion: "",
                      props: _vm.defaultProps,
                      "default-expand-all": true,
                    },
                    on: { check: _vm.clickDeal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.abrogateAdd("addform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }