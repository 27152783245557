<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form label-position="right" label-width="80px" class="topS">
          <el-row :gutter="10" style="margin-bottom: 0px">
            <el-col :span="3">
              <el-button @click="addBtn" v-if="showadd != -1">新增</el-button>
              <el-button @click="batchesDelete" v-if="showdelete != -1"
                >批量删除</el-button
              >
              <div style="color: #fff; display: inline-block">.</div>
            </el-col>
            <el-col :span="4">
              <el-form-item label="角色名">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.roleName"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="创建人">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.createUser"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="创建时间">
                <el-date-picker
                  v-model="createTime"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7" class="reset-button">
              <el-button type="primary" @click="handleSearch" :loading="loading"
                >查询</el-button
              >
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="roleName" label="角色"></el-table-column>
            <el-table-column prop="createUser" label="创建人"></el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column label="修改时间">
              <template slot-scope="scope">{{ scope.row.modifyTime }}</template>
            </el-table-column>
            <!-- <el-table-column prop="remark" label="描述" show-overflow-tooltip></el-table-column> -->

            <!-- 操作按钮列 -->
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <!-- 编辑按钮 -->
                <el-button
                  v-if="showupdate != -1"
                  type="primary"
                  size="mini"
                  dialogFormVisible
                  @click="handleEdit(scope.row)"
                  >修改</el-button
                >
                <!-- 删除按钮 -->
                <el-button
                  v-if="showdelete != -1"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
    <!-- 编辑的弹框 -->
    <el-dialog
      title="编辑角色"
      :visible.sync="dialogFormVisible2"
      :before-close="handleCloseEdit"
    >
      <el-form :model="editData" ref="editData" :rules="myrules">
        <div class="flex-row">
          <el-form-item
            label="默认登录页面"
            prop="rolePath"
            :label-width="formLabelWidth"
          >
            <!-- <el-input v-model.trim="editData.rolePath"></el-input>routerData -->
            <el-select
              v-model="editData.rolePath"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in routerData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item
          label="名称"
          prop="roleName"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="editData.roleName"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="权值" prop="leve" :label-width="formLabelWidth">
          <el-input-number v-model="editData.level" :min="1" :max="10" label="描述文字"></el-input-number>
        </el-form-item>-->
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            v-model="editData.remark"
            placeholder="请输入描述信息"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限选择" :label-width="formLabelWidth">
          <!-- <el-checkbox v-model="checked">全选</el-checkbox> -->
          <el-tree
            :data="menuTreeData"
            :default-checked-keys="editData.menuId"
            ref="trees"
            show-checkbox
            highlight-current
            accordion
            check-strictly
            node-key="id"
            :props="defaultProps"
            :default-expand-all="true"
            @check="clickDeal"
          ></el-tree>
          <!-- check-strictly -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('editData')">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmEditD('editData')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 新增的弹框 -->
    <el-dialog
      title="新增角色"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <el-form :model="addform" ref="addform" :rules="myrules">
        <el-form-item
          label="默认登录页面"
          prop="rolePath"
          :label-width="formLabelWidth"
        >
          <!-- <el-input v-model.trim="addform.rolePath"></el-input> -->
          <el-select
            v-model="addform.rolePath"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in routerData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="roleName"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="addform.roleName"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            v-model="addform.remark"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>

        <el-form-item label="权限选择" :label-width="formLabelWidth">
          <el-tree
            :data="menuTreeData"
            ref="tree"
            node-key="id"
            :default-checked-keys="addform.menuId"
            highlight-current
            show-checkbox
            check-strictly
            accordion
            :props="defaultProps"
            :default-expand-all="true"
            @check="clickDeal"
          ></el-tree>
          <!-- @node-click="nodeClick"
          @check-change="handleClick"
          @current-change="currentnode"-->
          <!-- check-strictly -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="abrogateAdd('addform')">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmAdd('addform')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoleList,
  addRole,
  delRole,
  getRights,
  getRightsId,
  editRole,
  nameVerify,
  getUserJurisdiction,
  getBrandSelect,
  getHotelSelect,
  // getMenuList,
} from "@/api";
import kanlist from "@/router/kanlist.js";
import benchs from "@/router/benchs.js";
import controlLis from "@/router/control.js";

export default {
  name: "jsgl",
  data() {
    /* 品牌名是否重复校验 */
    let Verify = (rule, value, callback) => {
      if (value === null) {
        callback(new Error("不能为空"));
      } else {
        nameVerify("role", {
          roleName: value,
          roleId: this.editData.roleId,
        }).then((res) => {
          if (res.data.data) {
            callback();
          } else {
            callback(new Error("你输入的已存在，请重新输入"));
          }
        });
      }
    };
    return {
      height: null,
      checked: false,
      userJurisdiction: null,
      multipleSelection: [],
      // 角色列表数据
      tableData: [],
      dialogFormVisible2: null,
      dialogFormVisible: null,
      dialogVisible: false,
      formLabelWidth: "120px",
      // 查询的数据
      createTime: null,
      seekData: {
        roleName: null,
        createUser: null,
        createTimeFrom: null,
        createTimeTo: null,
        pageSize: 10,
        pageNum: 1,
      },
      // 新增
      addform: {
        rolePath: null,
        // rolePath: '/kfjk',
        roleName: null, //角色名称
        remark: null, //角色描述
        menuId: [], //权限id
        // hotelId: null,
        // brandId: null,
      },
      brandsData: null,
      hotelsData: null,
      menuTreeData: null,
      allTreeKeys: null,
      // 编辑
      loading: false,
      total: null,
      putData: {
        roleName: null,
        remark: null,
        roleId: null,
        menuId: [],
        pageSize: 10,
        pageNum: 1,
      },
      editData: {
        rolePath: null,
        roleName: null,
        remark: null,
        roleId: null,
        menuId: [],
        // brandId: null,
        // hotelId: null,
      },
      checkedAllId: [],
      myrules: {
        rolePath: [
          { required: true, message: "请选择默认登录页面", trigger: "blur" },
        ],
        roleName: [{ required: true, validator: Verify, trigger: "blur" }],
        brandId: [{ required: true, message: "请选择品牌", trigger: "change" }],
        hotelId: [{ required: true, message: "请选择门店", trigger: "change" }],
      },

      defaultProps: {
        children: "children",
        label: "name",
        // label: "title",
      },
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },
  computed: {
    routerData() {
      let arr = [
        {
          label: "故障监控",
          value: "/gzjk",
        },
        {
          label: "客房监控",
          value: "/kfjk",
        },
      ];
      benchs.forEach((v) => {
        arr.push({
          label: v.meta.title,
          value: v.path,
        });
      });
      kanlist.forEach((v) => {
        arr.push({
          label: v.meta.title,
          value: v.path,
        });
      });
      controlLis.forEach((v) => {
        arr.push({
          label: v.meta.title,
          value: v.path,
        });
      });

      return arr;
    },
    // 权限
    showadd: function () {
      return this.userJurisdiction.indexOf("role:add");
    },
    showdelete: function () {
      return this.userJurisdiction.indexOf("role:delete");
    },
    showupdate: function () {
      return this.userJurisdiction.indexOf("role:update");
    },
  },
  // 注册表格组件
  components: {},
  watch: {
    checked: {
      handler(newVal, oldVal) {
        if (newVal) {
          // this.$refs.trees.setCheckedNodes(this.menuTreeData)
        } else {
          // this.$refs.trees.setCheckedKeys([]);
        }
      },
    },
  },
  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.seekData.brandId = this.user.brandId;
    this.seekData.storeId = this.user.storeId;
    // 调用初始化表格数据方法
    this.initList(this.seekData);
    this.getPermission();
    this.getBrandsData();
  },
  methods: {
    clickDeal(currentObj, treeStatus) {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      let selected = treeStatus.checkedKeys.indexOf(currentObj.id); // -1未选中
      // 选中
      if (selected !== -1) {
        // 子节点只要被选中父节点就被选中
        this.selectedParent(currentObj, true);
        // 统一处理子节点为相同的勾选状态
        this.uniteChildSame(currentObj, true);
      } else {
        // 未选中 处理子节点全部未选中
        // if (currentObj.children) {
        this.uniteChildSame(currentObj, false);
        // this.selectedParent(currentObj, false)
        // this.isCancelParent(currentObj.id)
        // }
      }
    },
    // 统一处理子节点为相同的勾选状态
    uniteChildSame(treeList, isSelected) {
      if (this.dialogFormVisible2) {
        this.$refs.trees.setChecked(treeList.id, isSelected);
      }
      if (this.dialogFormVisible) {
        this.$refs.tree.setChecked(treeList.id, isSelected);
      }

      if (treeList.children) {
        for (let i = 0; i < treeList.children.length; i++) {
          // this.$refs.trees.setChecked(treeList.children[i], isSelected)
          this.uniteChildSame(treeList.children[i], isSelected);
        }
      }
    },
    // 统一处理父节点为选中
    selectedParent(currentObj, isSelected) {
      let currentNode;
      if (this.dialogFormVisible2) {
        currentNode = this.$refs.trees.getNode(currentObj);
      }
      if (this.dialogFormVisible) {
        currentNode = this.$refs.tree.getNode(currentObj);
      }

      if (currentNode.parent.key !== undefined) {
        if (this.dialogFormVisible2) {
          this.$refs.trees.setChecked(currentNode.parent, isSelected);
        }
        if (this.dialogFormVisible) {
          this.$refs.tree.setChecked(currentNode.parent, isSelected);
        }

        this.selectedParent(currentNode.parent, isSelected);
      }
    },

    getBrothersNode(id, data) {
      let onOff = false;
      data.forEach((item) => {
        if (item.id === id) {
          // debugger
          // return data
          onOff = true;
        }
        if (!onOff && item.children) {
          this.getBrothersNode(id, item.children);
        }
      });
      if (onOff) {
        return data;
      }
    },

    getAllChild(list) {
      var returnList = [];
      var obj = null;

      for (var key in list) {
        obj = list[key];

        if (obj) {
          returnList.push(obj.id);

          if (obj.hasChildren) {
            returnList.push(this.getAllChild(obj.children));
          }
        }
      }
      return returnList;
    },
    findAllPerObjByPid(tree, pId) {
      var p_id = pId;
      var returnList = [];
      var obj = null;

      while (true) {
        obj = this.findByIdFromTree(tree, p_id);

        if (obj) {
          returnList.push(obj.id);

          if (obj.parentId - 0 != 0) {
            p_id = obj.parentId;
          } else {
            break;
          }
        } else {
          break;
        }
      }
      return returnList;
    },
    findByIdFromTree(tree, id) {
      var returnJson = null;
      var obj = null;

      //遍历树结构
      for (var key in tree) {
        obj = tree[key]; //获取当前遍历的对象

        //判断对象是否为空
        if (obj) {
          //判断对象是否为获取的对象
          if (obj.id == id) {
            //如果是，直接返回
            returnJson = obj;
            break;
          } else if (obj.hasChildren) {
            //如果不是但是有子节点，递归查找
            returnJson = this.findByIdFromTree(obj.children, id);

            //如果递归回来有对象，终止当前循环
            if (returnJson) {
              break;
            }
          }
        }
      }
      return returnJson;
    },
    currentnode(data, node) {},
    // 获取菜单栏
    getMenuList() {
      var data = JSON.parse(sessionStorage.getItem("user"));
      var user = data.username;
      getUserJurisdiction(user).then((res) => {
        this.items = res.data.data[0].children;
        this.deleteBtn(this.items);
        this.$store.commit("GET_MENU_LIST", this.items);
      });
    },
    deleteBtn(list) {
      let _obj = null; //临时对象
      let _index = 0; //下标

      //直接无限循环
      while (true) {
        _obj = list[_index]; //获取当前下标的对象

        //如果对象的type为1，直接删除
        if (_obj.type == "1") {
          list.splice(_index, 1);

          //删除后，如果下标已经越界，证明上面所删除的已经是最后一个
          if (_index == list.length) {
            break;
          } else {
            //如果还没有越界，继续处理当前下标的数据，因为删除当前下标数据后，后面的数会向前推进一位
            continue;
          }
        } else {
          //如果对象的type为0
          if (_obj.children) {
            //如果对象有子数据
            this.deleteBtn(_obj.children); //直接递归处理

            //如果递归完成之后，被处理对象的子数据长度为0时，把这个子数据集删除
            if (_obj.children.length == 0) {
              delete list[_index].children;
            }
          }
        }

        //如果循环到list的下标，跳出循环
        if (_index == list.length - 1) {
          break;
        } else {
          //递增1
          _index++;
        }
      }
    },

    // 初始化表格数据
    initList(obj) {
      this.loading = true;
      getRoleList(obj).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.seekData.pageNum > 1 && res.data.data.rows == false) {
            this.seekData.pageNum = this.seekData.pageNum - 1;
            this.initList(this.seekData);
          }
          // 给totalNum赋值
          // this.totalNum = res.data.data.total
        }
      });
    },

    // 获取品牌信息
    getBrandsData() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandsData = res.data.data;
          // this.total = res.data.total
        }
      });
    },

    // 获取门店信息
    selectOne(val) {
      this.addform.hotelId = null;
      getHotelSelect(val).then((res) => {
        if (res.data.data) {
          this.hotelsData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店",
            type: "warning",
          });
        }
      });
    },

    // 获取权限列表
    getPermission() {
      var data = JSON.parse(sessionStorage.getItem("user"));
      var user = data.username;
      getRights(user).then((res) => {
        this.menuTreeData = res.data.data[0].children;
      });
      // getMenuList().then((res) => {
      //   this.menuTreeData = res.data.data.rows.children
      // });
    },

    // 新增弹窗
    addBtn() {
      this.dialogFormVisible = true;
    },

    // 确定按钮
    confirmAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addform.menuId = this.$refs.tree.getCheckedKeys();
          if (this.addform.menuId == false) {
            this.$message({
              type: "error",
              message: "请选择权限",
            });
            return;
          }
          this.newform = JSON.parse(JSON.stringify(this.addform));
          this.loading = true;
          addRole(
            Object.assign(this.newform, {
              menuId: this.$refs.tree.getCheckedKeys().join(","),
            })
          )
            .then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.$message.success(res.data.message);
                this.initList(this.seekData);
                // this.reset()
                this.$refs[formName].resetFields();
                this.clearAddData();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },

    // 取消添加按钮
    abrogateAdd(formName) {
      this.$refs[formName].resetFields();
      this.clearAddData();
    },

    // 关闭新增弹窗
    handleClose() {
      this.$refs.addform.resetFields();
      this.clearAddData();
    },

    // 清除添加数据
    clearAddData() {
      this.addform.remark = "";
      this.addform.menuId = [];
      this.$refs.tree.setCheckedKeys([]);
      this.dialogFormVisible = false;
    },

    // 删除接口
    deleteRoleInfo(data) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          delRole(data).then((res) => {
            this.loading = false;
            if (res.data.code == "000000") {
              this.$message.success(res.data.message);
              // this.seekData.pageNum = 1
              this.initList(this.seekData);
              // this.reset()
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 删除用户
    handleDelete(row) {
      this.deleteRoleInfo(row.roleId);
    },
    // 批量删除
    batchesDelete() {
      if (this.multipleSelection.length != 0) {
        // 把要删除的角色ID以字符串拼接
        let number = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          number += element.roleId + ",";
        }
        number = number.slice(0, number.length - 1); //去掉最后的逗号
        this.deleteRoleInfo(number);
      } else {
        this.$message.warning("请先选择要删除的数据");
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 点击查看用户详情
    handleDetails(row) {
      this.dialogVisible = true;
      getRights().then((res) => {
        this.menuTreeData = res.data.data.rows.children;
        this.allTreeKeys = res.data.data.ids;
      });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 编辑用户
    handleEdit(index, row) {
      this.dialogFormVisible2 = true;
      this.editData.rolePath = index.rolePath;
      this.editData.roleName = index.roleName;
      this.editData.remark = index.remark;
      this.editData.roleId = index.roleId;
      this.editData.level = index.level;
      // this.editData.brandId = index.brandId
      // this.editData.hotelId = index.hotelId
      // this.selectOne(index.brandId)
      this.loading = true;
      getRightsId(index.roleId).then((res) => {
        this.editData.menuId = res.data.data;
        this.loading = false;
        // this.editData.menuId = ["85", "59", "37", "2", "5", "3", "9"]
        // this.editData.menuId = ["85", "59", "35"]
        // this.editData.menuId = ["85", "59", "35", "31", "32", "33", "34"]
      });
    },
    // 编辑用户确认
    confirmEditD(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.putData = JSON.parse(JSON.stringify(this.editData));
          let newData = Object.assign(this.putData, {
            menuId: this.$refs.trees.getCheckedKeys().join(","),
          });
          // let newData = Object.assign(this.putData, {
          //   menuId: [].concat(this.$refs.trees.getCheckedKeys(), this.$refs.trees.getHalfCheckedKeys())
          // })
          // let menuId = [].concat(this.$refs.trees.getCheckedKeys())
          // let allMenuId = [].concat(this.$refs.trees.getCheckedKeys(), this.$refs.trees.getHalfCheckedKeys())
          editRole(newData).then((res) => {
            //  console.log(res);
            this.loading = false;
            if (res.data.code === "000000") {
              this.dialogFormVisible2 = false;
              this.initList(this.seekData);
              // this.reset()
              this.getMenuList();
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
            this.$refs[formName].resetFields();
            this.clearEditData();
          });
        } else {
          return false;
        }
      });
    },

    // 取消按钮（编辑）
    handleCancel(formName) {
      this.$refs[formName].resetFields();
      this.clearEditData();
      this.dialogFormVisible2 = false;
    },

    handleCloseEdit() {
      this.$refs.editData.resetFields();
      this.clearEditData();
      this.dialogFormVisible2 = false;
    },

    // 清除编辑数据
    clearEditData() {
      this.editData.remark = "";
      this.editData.menuId = [];
      this.$refs.trees.setCheckedKeys([]);
    },

    // 查询按钮
    handleSearch() {
      this.loading = true;
      this.seekData.pageNum = 1;
      if (this.createTime) {
        this.seekData.createTimeTo = this.createTime[1] + " 23:59:59";
        this.seekData.createTimeFrom = this.createTime[0] + " 00:00:00";
      } else {
        this.seekData.createTimeTo = null;
        this.seekData.createTimeFrom = null;
      }
      getRoleList(this.seekData).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
          this.loading = false;
        }
      });
    },
    // 重置按钮
    reset() {
      this.createTime = null;
      this.seekData.roleName = null;
      this.seekData.createUser = null;
      this.seekData.createTimeFrom = null;
      this.seekData.createTimeTo = null;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },

    //分页
    handleCurrentChange(cpage) {
      this.putData.pageNum = cpage;
      this.seekData.pageNum = cpage;
      this.initList(this.seekData);
    },
    handleSizeChange(psize) {
      this.putData.pageSize = psize;
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
  },
};
</script>
<style lang="scss" scoped>
.my-box {
  .my-iemt {
    span {
      font-size: 14px;
    }
    white-space: nowrap;
    // .my-input {
    //   max-width: 200px;
    // }
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
    span {
      font-size: 14px;
    }
  }
}

.my-box .flex-row {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
</style>
